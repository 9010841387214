import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('../views/Home.vue')
const About = () => import('../views/About.vue')
const Introduction = () => import('../views/Introduction.vue')
const Access = () => import('../views/Access.vue')
const Link = () => import('../views/Link.vue')
const History = () => import('../views/History.vue')
const PrivacyPolicy = () => import('../views/Privacypolicy.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: Introduction
  },
  {
    path: '/access',
    name: 'access',
    component: Access
  },
  {
    path: '/link',
    name: 'link',
    component: Link
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy
  },
  {
    path: '/history',
    name: 'history',
    component: History
  },
  {
    path: '*',
    name: 'fallback',
    component: Home
  }
]

const scrollBehavior = (to: any, from: any, savedPosition: any) => {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}

const router = new VueRouter({
  routes,
  scrollBehavior,
  mode: 'history'
})

export default router
