
import Header from '@/components/Header.vue'
import Main from '@/components/Main.vue'
import Navigation from '@/components/Nav.vue'

export default {
  components: {
    Header,
    Main,
    Navigation
  }
}
