<template>
  <header>
    <div class="header-inner">
      <router-link :to="{ name: 'home' }"
        ><img
          id="nav-title"
          src="@/assets/img/miki_ihara_logo.svg"
          alt="三木・伊原法律特許事務所"
          v-on:click="hide"
      /></router-link>
      <div
        id="nav-hide"
        v-bind:class="navHideClass"
        v-on:touchstart="hide"
        v-on:mousedown="hide"
      ></div>
      <div id="nav-drawer">
        <div id="nav-icon" v-on:click="toggle"><span></span></div>
        <transition name="nav">
          <div id="nav-content" v-if="show">
            <ul>
              <li>
                <router-link :to="{ name: 'history' }"
                  ><img
                    src="@/assets/img/sp-nav-history.svg"
                    alt="事務所の沿革"
                    v-on:click="hide"
                /></router-link>
              </li>
              <li>
                <router-link :to="{ name: 'about' }"
                  ><img
                    src="@/assets/img/sp-nav-about.svg"
                    alt="業務内容"
                    v-on:click="hide"
                /></router-link>
              </li>
              <li>
                <router-link :to="{ name: 'introduction' }"
                  ><img
                    src="@/assets/img/sp-nav-introduction.svg"
                    alt="弁護士・弁理士紹介"
                    v-on:click="hide"
                /></router-link>
              </li>
              <li>
                <router-link :to="{ name: 'access' }"
                  ><img
                    src="@/assets/img/sp-nav-access.svg"
                    alt="アクセス"
                    v-on:click="hide"
                /></router-link>
              </li>
              <li>
                <router-link :to="{ name: 'link' }"
                  ><img
                    src="@/assets/img/sp-nav-link.svg"
                    alt="リンク"
                    v-on:click="hide"
                /></router-link>
              </li>
              <li>
                <router-link
                  class="link-privacy"
                  :to="{ name: 'privacy-policy' }"
                  ><img
                    src="@/assets/img/sp-nav-privacypolicy.svg"
                    alt="プライバシーポリシー"
                    v-on:click="hide"
                /></router-link>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script type="ts">
export default {
  name: 'Header',
  data () {
    return {
      show: false
    }
  },
  methods: {
    // メニューの表示・非表示切替
    toggle: function (event) {
      this.show = !this.show
    },

    // メニューの非表示
    hide: function (event) {
      this.show = false
    }
  },
  computed: {
    navHideClass: function () {
      return {
        'disable-menu-area': this.show,
        hidden: !this.show
      }
    }
  }
}
</script>

<!-- 参考: https://saruwakakun.com/html-css/reference/nav-drawer -->
<style scoped>
.hidden {
  display: hidden;
}

div.disable-menu-area {
  position: fixed;
  top: 80px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 1);
}

div.header-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  height: 100%;
  margin-left: 5%;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}

img#nav-title {
  margin: 20px 15px;
  height: 40px;
}

#nav-drawer {
  position: relative;
}

/* ハンバーガーメニュー */
#nav-icon {
  display: inline-block;
  width: 30px;
  height: 22px;
  margin-top: 25px;
  margin-left: 8px;
  z-index: 2;
}

#nav-icon span,
#nav-icon span:before,
#nav-icon span:after {
  position: absolute;
  height: 3px;
  width: 25px;
  border-radius: 3px;
  background: #555;
  display: block;
  content: "";
  cursor: pointer;
}

#nav-icon span:before {
  bottom: -12px;
}

#nav-icon span:after {
  bottom: -24px;
}

#nav-content {
  overflow: auto;
  position: fixed;
  top: 80px;
  right: 0;
  z-index: 3;
  background: linear-gradient(
    180deg,
    rgb(235, 235, 237),
    rgb(255, 255, 255) 50px
  );
  box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
}

.nav-enter-active,
.nav-leave-active {
  transition: all 0.3s ease-in-out;
}

.nav-enter,
.nav-leave-to {
  transform: translateX(50px);
  opacity: 0;
}

ul {
  list-style-type: none;
  padding-left: 5px;
  margin: 10px;
  text-align: right;
}

li {
  margin: 35px 15px;
}

img.nav-title {
  height: 40px;
}

ul li img {
  height: 15px;
}
</style>
